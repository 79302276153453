.benefit {
  min-width: 30rem;
  max-width: 93rem;

  &-title {
    font-size: 1.6rem;
    font-weight: $font-weight-base;
    line-height: 2rem;
    padding: 3.5rem 2rem;
    background-color: var(--kt-light-active);
    border-radius: 3rem 3rem 0 0;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      padding: 0 2rem;
      line-height: 2.25rem;
      border-radius: 3rem 0 0 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 23rem;
      max-width: 23rem;
    }

    h3 {
      max-width: 30.3rem;
    }
  }

  &-content {
    padding: 3rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
      max-width: 51rem;
    }

    &-headline {
      font-size: 1.6rem;
      font-weight: $font-weight-bold;
      line-height: 2rem;
      text-transform: uppercase;
      margin-bottom: 1.6rem;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
        line-height: 2.75rem;
        margin-bottom: 2.4rem;
      }
    }

    &-text {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;
      margin-bottom: 2.5rem;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 2.25rem;
        margin-bottom: 3rem;
      }

      span {
        font-weight: $font-weight-bold;
        line-height: 2rem;
        font-size: 1.6rem;
        background-color: #ecf1ee;
        height: 1.5rem;
        padding: 0 0.4rem;

        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
          line-height: 2.25rem;
        }
      }
    }
  }

  &-action {
    &-buttons {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 2.25rem;
      }
    }

    &-button-keep {
      padding: 1.3rem 11rem;
      color: var(--kt-white);
      background-color: var(--kt-secondary);
      font-size: 1.8rem;
      line-height: 2.25rem;
      border-radius: 3rem;
      width: 100%;
      margin-bottom: 3rem;
      white-space: nowrap;
      font-weight: $font-weight-bold;
      max-height: 4.8rem;

      @include media-breakpoint-up(md) {
        padding: 1.8rem 7.4rem;
        max-height: 6rem;
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 4rem;
      }
    }
  }

  &-list {
    padding: 1.5rem;
    margin-bottom: 1.6rem;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 1.2rem;
    line-height: 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 2rem;
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    p {
      margin-bottom: 2rem;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 2.3rem;
        margin-bottom: 1rem;
        padding-right: 1.6rem;

        @include media-breakpoint-up(md) {
          padding-left: 3.2rem;
          max-width: none;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.8rem;
          height: 1.8rem;

          background-size: contain;
          background-repeat: no-repeat;
          margin-bottom: 1.1rem;

          @include media-breakpoint-up(md) {
            width: 2.2rem;
            height: 2.2rem;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
