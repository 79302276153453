// Media of at most the maximum and minimum breakpoint widths. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint.

@mixin media-breakpoint-direction($direction, $name, $breakpoints: $grid-breakpoints) {
    @if $direction == up {
        $min: breakpoint-min($name, $breakpoints);

        @if $min {
            @media (min-width: $min) {
                @content;
            }
        } @else {
            @content;
        }

    } @else if $direction == down {
        $max: breakpoint-max($name, $breakpoints);

        @if $max {
            @media (max-width: $max) {
                @content;
            }
        }
    }
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(  xs: 475px, sm: 640px,md: 768px,lg: 1024px,xl: 1280px,xxl: 1536px,)
//    576px
@function breakpoint-min($name) {
    $min: map-get($grid-breakpoints, $name);
    @return if($min !=0, $min, null);
}

// Maximum breakpoint width.
@function breakpoint-max($name) {
    $max: map-get($grid-breakpoints, $name);
    @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name) {
    $min: breakpoint-min($name);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }

    @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name) {
    $max: breakpoint-max($name);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }

    @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper) {
    $min: breakpoint-min($lower);
    $max: breakpoint-max($upper);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }

    @else if $max ==null {
        @include media-breakpoint-up($lower) {
            @content;
        }
    }

    @else if $min ==null {
        @include media-breakpoint-down($upper) {
            @content;
        }
    }
}
