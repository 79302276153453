.cancel-subscription {
  &-item {
    padding: 2rem;
    border: 1.5px solid #f7e9b8;
    border-radius: 3rem;
    background-color: var(--kt-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    position: relative;
    z-index: 20;

    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 3rem 2rem 2rem 2rem;
    }

    &:hover {
      box-shadow: 0px 0px 5px 0px #edbf69;
      cursor: pointer;
    }

    // DONT REMOVE THIS - IT WILL BE USED LATER FOR PAUSE/SKIP
    // &.selected {
    //   box-shadow: 0px 0px 5px 0px #edbf69;
    //   cursor: pointer;

    //   &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: -1.5rem;
    //     z-index: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: 2.2rem;
    //     height: 1.4rem;
    //     background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11" fill="none"%3E%3Cpath d="M10 11L0.47372 0.5L19.5263 0.500002L10 11Z" fill="%23FAF0CD"/%3E%3C/svg%3E')
    //       no-repeat center center;
    //     background-size: contain;
    //   }
    // }

    &:last-child {
      border: 1px solid #c1c1c1;
      margin-bottom: 0;

      &:hover {
        box-shadow: 0px 0px 5px 0px #c1c1c1;
        cursor: pointer;
      }

      // DONT REMOVE THIS - IT WILL BE USED LATER FOR PAUSE/SKIP
      // &.selected {
      //   box-shadow: 0px 0px 5px 0px #c1c1c1;

      //   &::after {
      //     content: '';
      //     position: absolute;
      //     bottom: -1.5rem;
      //     z-index: 0;
      //     left: 50%;
      //     transform: translateX(-50%);
      //     width: 2.2rem;
      //     height: 1.4rem;
      //     background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11" fill="none"%3E%3Cpath d="M10 11L0.47372 0.5L19.5263 0.500002L10 11Z" fill="%23e9e9e9"/%3E%3C/svg%3E')
      //       no-repeat center center;
      //     background-size: contain;
      //   }
      // }
    }

    &-title {
      color: #4d4d4d;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-bold;
      margin-bottom: 2rem;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 2.25rem;
      }
    }

    &-list {
      li {
        margin-bottom: 1rem;
        color: #4d4d4d;
        font-size: 1.4rem;
        line-height: 1.75rem;
        font-weight: $font-weight-base;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
          line-height: 2rem;
          margin-bottom: 1.5rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-label {
      font-weight: $font-weight-base;
      color: var(--kt-primary);
      margin-bottom: 4.2rem;
      margin-left: 2.9rem;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-weight: $font-weight-extra-bold;
        margin-bottom: 2.6rem;
        margin-left: 0;
        text-align: center;
      }
    }
  }

  &-button {
    background-color: #faf0cd;
    border-radius: 4.5rem;
    padding: 1.4rem 4.3rem;
    font-weight: $font-weight-bold;
    max-height: 4.8rem;
    margin-top: 2.5rem;
    min-width: 26rem;
    font-size: 1.6rem;
    line-height: 2rem;
    z-index: 100;

    &:hover {
      background-color: #f7e9b8;
      box-shadow: 0px 0px 5px 0px #edbf69;
      cursor: pointer;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   bottom: -1.5rem;
      //   z-index: 0;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   width: 2.2rem;
      //   height: 1.4rem;
      //   background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11" fill="none"%3E%3Cpath d="M10 11L0.47372 0.5L19.5263 0.500002L10 11Z" fill="%23FAF0CD"/%3E%3C/svg%3E')
      //     no-repeat center center;
      //   background-size: contain;
      // }
    }

    @include media-breakpoint-up(md) {
      padding: 1.3rem 3.2rem;
      font-size: 1.8rem;
      line-height: 2.25rem;
    }

    &-secondary {
      color: var(--kt-white);
      background-color: var(--kt-secondary);
      border-radius: 4.5rem;
      padding: 0.65rem 2.4rem;
      font-weight: $font-weight-extra-bold;
    }
  }

  &-btn {
    text-align: center;
    background-color: $gray-200;
    border-radius: 4.5rem;
    padding: 1.25rem 4.6rem;
    font-weight: $font-weight-bold;
    margin-top: 2.5rem;
    min-width: 26rem;

    &:hover {
      background-color: #c1c1c1;
    }
  }
}

.delay-subscription {
  &-order-interval {
    list-style-type: disc;
    color: var(--kt-primary);

    &-title {
      color: var(--kt-primary);
      font-weight: $font-weight-extra-bold;
      font-size: 2rem;
      padding: 0 2.4rem;
      margin-bottom: 1.6rem;
      display: block;
    }

    &-item {
      box-shadow: var(--hb-box-shadow);
      list-style: none;
      border-radius: 3rem;
      width: fit-content;
      background-color: var(--kt-white);
      margin-bottom: 2.3rem;
      padding-left: 2.9rem;
      padding-right: 6.5rem;
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
      margin-left: -2rem;
      opacity: 1;

      &.selected {
        opacity: 1;
      }

      &.not-selected {
        opacity: 0.8;
      }

      @include media-breakpoint-up(md) {
        margin-left: -2rem;
        padding-left: 6.5rem;
      }
    }
  }

  &-wrapper {
    padding-left: 3rem;
    padding-right: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
      padding: 5rem;
      min-width: 36rem;
    }

    &-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 3.6rem;

      li {
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-up(md) {
        font-weight: $font-weight-base;
        font-size: 1.8rem;
        margin-bottom: 2rem;
        line-height: 1.25;
      }
    }
  }

  &-delivery-interval {
    position: relative;
    height: fit-content;
    color: var(--kt-primary);
    border: 2px solid var(--kt-primary);
    box-shadow: var(--hb-box-shadow);
    border-radius: 3rem;
    padding-left: 2rem;
    padding-right: 5.5rem;
    padding-bottom: 2.25rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-top: 6rem;
    }

    @include media-breakpoint-up(xl) {
      min-width: 35rem;
    }

    &-label {
      font-weight: $font-weight-base;
      font-size: 1.2rem;
      position: absolute;
      top: -1rem;
      background-color: var(--kt-white);
      padding: 0 0.75rem;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
        left: 2rem;
        top: -1.25rem;
      }
    }

    &-image {
      position: absolute;
      top: -3.5rem;
      background-color: var(--kt-white);
      padding-left: 0.75rem;
      right: 2rem;

      @include media-breakpoint-up(md) {
        right: 3rem;
      }
    }
  }

  &-bg {
    background-color: var(--kt-primary-light);
    border-radius: 3rem;
    padding-top: 1.5rem;
    box-shadow: var(--hb-box-shadow);
    height: fit-content;

    @include media-breakpoint-up(md) {
      padding: 2.8rem 0;
    }
  }

  &-item {
    box-shadow: var(--hb-box-shadow);
    list-style: none;
    border-radius: 3rem;
    width: fit-content;
    background-color: var(--kt-white);
    margin-bottom: 2.3rem;
    padding-left: 2.9rem;
    padding-right: 6.5rem;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    width: 100%;
    margin-left: -2rem;
    opacity: 1;

    &.selected {
      opacity: 1;
    }

    &.not-selected {
      opacity: 0.8;
    }
  }
}

.delay-order {
  &-item {
    box-shadow: var(--hb-box-shadow);
    list-style: none;
    border-radius: 3rem;
    width: fit-content;
    background-color: var(--kt-white);
    margin-bottom: 2.3rem;
    padding-left: 2.9rem;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    margin-left: -2rem;

    @include media-breakpoint-up(md) {
      margin-left: -4rem;
    }
  }

  &-input {
    accent-color: var(--kt-primary) !important;
    margin-right: 1.2rem;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      width: 2.3rem;
      height: 2.3rem;
      margin-right: 2.2rem;
    }
  }
}
