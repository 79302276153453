.dashed-border {
  width: 100%;
  height: 0.1rem;
  background-image: linear-gradient(
      90deg,
      transparent,
      transparent 50%,
      var(--kt-white) 50%,
      var(--kt-white) 100%
    ),
    linear-gradient(
      90deg,
      var(--kt-primary),
      var(--kt-primary),
      var(--kt-primary),
      var(--kt-primary),
      var(--kt-primary)
    );
  background-size: 1rem 0.2rem, 1rem 100%;
  border: none;
  margin: 2rem 0 2rem 0;

  @include media-breakpoint-up(md) {
    height: 0.3rem;
  }
}

.intro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 3rem;

  @include media-breakpoint-up(md) {
    border: none;
    margin: auto;
  }

  .intro-image-wrapper {
    display: flex;
    min-height: 10.4rem;

    img {
      width: auto;

      @media only screen and (max-width: 400px) {
        max-height: 10.4rem;
      }

      @include media-breakpoint-up(md) {
        min-height: 66rem;
        object-fit: cover;
        height: auto;
      }
    }
  }

  &-info-headline {
    font-size: 1.8rem;
    line-height: 2.25rem;
    font-weight: $font-weight-bold;
    margin-bottom: 1.6rem;

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
      line-height: 2.75rem;
      min-width: max-content;
      margin-bottom: 2.4rem;
    }

    span {
      color: var(--kt-primary);
      font-weight: $font-weight-extra-bold;
    }
  }

  .intro-info-subheadline {
    font-weight: $font-weight-base;
    line-height: 1.75rem;
    font-size: 1.4rem;
    color: var(--kt-primary);
    max-width: 17.4rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      max-width: 100%;
      line-height: 2.25rem;
      font-weight: $font-weight-bold;
      white-space: nowrap;
    }
  }

  .test-package-food {
    width: 3rem;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 5rem;
      display: flex;
      margin: 0 auto 0.5rem auto;
      justify-content: center;
      align-items: center;
    }
  }

  .calories {
    color: var(--kt-primary);
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 2rem;
    width: 50%;
    text-align: right;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.25rem;
    }

    .price-span {
      font-size: 1.8rem;
      color: var(--kt-black);
      margin-right: 0;

      @include media-breakpoint-up(md) {
        font-size: 3rem;
        line-height: 2.8rem;
        margin-right: 0.5rem;
      }
    }
  }

  .intro-info {
    padding: 2rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    .intro-btn {
      background-color: var(--kt-secondary);
      color: var(--kt-white);
      border-radius: 3rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      .recommendation-submit {
        border-radius: 0 0 3rem 3rem;
      }

      .intro-link {
        width: 100%;
        color: var(--kt-white);
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        outline: none;
        line-height: 3rem;
        height: 4.8rem;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
          padding: 0 4rem;
          height: 6rem;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.updated-recipes {
  h4 {
    color: var(--kt-primary);
    font-weight: $font-weight-bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 2.4rem;
  }
}

.updated-subscription-price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3.2rem;
  }

  &-old {
    color: var(--kt-black);
    font-size: 1.4rem;
    font-weight: $font-weight-base;
    line-height: 1.75rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;

      line-height: 2.25rem;
    }
  }

  &-old-price {
    color: var(--kt-black);
    font-size: 1.6rem;
    font-weight: $font-weight-base;
    line-height: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;

      line-height: 2.25rem;
    }
  }

  &-new {
    color: var(--kt-primary);
    font-weight: $font-weight-extra-bold;
    font-size: 1.6rem;
    line-height: 2rem;
    max-width: 19rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      max-width: 100%;
    }
  }

  &-wrapper {
    color: var(--kt-primary);
    font-size: 2.6rem;
    font-weight: $font-weight-extra-bold;
    line-height: 3.25rem;

    span {
      font-size: 1.8rem;
      margin-top: 0.3rem;
      line-height: 2.5rem;
      text-align: end;
    }
  }
}

.update-title-wrapper {
  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;

    @include media-breakpoint-up(md) {
      margin-right: 1rem;
    }
  }
}

.updated-modal-close {
  font-size: 1.6rem;
  font-weight: $font-weight-base;
  line-height: 2rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    justify-content: left;
    font-size: 1.8rem;
  }
}

.updated-discount-banner {
  position: absolute;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  background: #ffd380;
  color: var(--kt-black);
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin-bottom: 2.3rem;
  right: -1rem;
  font-size: 1.4rem;
  line-height: 1.75rem;
  left: auto;
  text-align: right;
  border-radius: 2rem 0 0 2rem;
  padding: 0.9rem 3rem 0.9rem 2rem;

  @include media-breakpoint-up(md) {
    padding: 1.2rem 5rem 1.2rem 2rem;
    margin-bottom: 2rem;
  }
}

.updated-discount-banner::after {
  content: '';
  position: absolute;
  width: 0;
  left: auto;
  right: 0;
  border-right: 1rem solid transparent;
  border-left: none;
  height: 0;
  bottom: -1rem;
  border-top: 1rem solid #e7b14e;
}

.sachet-list {
  max-width: 12rem;
  font-weight: $font-weight-bold;
  line-height: 2.2rem;
  font-size: 1.6rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .sachet-icon {
    margin: 0 auto;
    width: 6.4rem;
    height: 6.4rem;
  }

  &-text {
    margin-bottom: 0.4rem;
    color: var(--kt-primary);
  }
}
