.pet-subscription {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &-wrapper {
    background-color: var(--kt-light);

    @include media-breakpoint-up(md) {
      border-radius: 3rem;
    }
  }

  &-title {
    font-weight: $font-weight-base;
    font-size: 1.8rem;
    text-transform: uppercase;
    color: var(--kt-primary);

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }
}

.pet-subscription-slick {
  max-width: 100vw;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    margin-bottom: 1.7rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }

  .swiper-slide {
    flex-shrink: 0;
    width: auto;
  }
}
