//
// Menu
//
.react-datepicker__input-container {
  border: 2px solid var(--kt-primary) !important;
  border-radius: 5px;
  padding: 5px;
}

textarea:focus,
input:focus {
  outline: none;
}

.custom-calendar {
  width: 300px;
  height: 25.3rem;
  font-size: 1.5rem;
  background-color: var(--kt-primary-light) !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  width: 300px;
  font-size: 1.2rem;
  background-color: var(--kt-primary) !important;
}

.react-datepicker__month {
  margin: 0 !important;
}

.react-datepicker__month,
.react-datepicker__day-name {
  font-size: 15px;
  color: var(--kt-white) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.7rem !important;
}

.react-datepicker__day-names {
  margin-bottom: -3px !important;
}

.react-datepicker__current-month {
  font-size: 1.3rem !important;
  color: var(--kt-white) !important;
}

.react-datepicker-ignore-onclickoutside {
  outline: none !important;
  border: none;
}

// Aside menu
.aside-menu {
  border-radius: 0 0 0 3rem;

  @include media-breakpoint-up(lg) {
    box-shadow: var(--hb-box-shadow);
  }

  // Item
  .menu-item {
    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        border-bottom: 0.1rem solid var(--kt-primary);
      }
    }

    // Menu Link
    .menu-link {
      color: var(--kt-primary);
      display: flex;
      align-items: center;
      gap: 1.2rem;
      padding: 2rem;

      cursor: pointer;

      flex: 0 0 100%;
      transition: none;
      outline: none !important;

      @include media-breakpoint-up(lg) {
        padding: 3rem 2rem 3rem 6rem;
      }

      &.active {
        color: var(--kt-black);

        .menu-icon svg {
          fill: var(--kt-primary);
        }

        .menu-title {
          font-weight: $font-weight-extra-bold;
        }
      }
    }

    // Menu title
    .menu-title {
      font-weight: $font-weight-base;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;
      flex-grow: 1;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    // Icon
    .menu-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $menu-icon-size;

      svg {
        height: 100%;
        width: 100%;

        @include media-breakpoint-up(lg) {
          height: 3.2rem;
          width: 3.2rem;
        }
      }
    }

    // arrow
    .menu-arrow {
      display: flex;
      align-items: stretch;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;
      margin-left: $menu-arrow-space;
      width: $menu-arrow-size;
      height: $menu-arrow-size;

      &:after {
        display: block;
        width: 100%;
        content: ' ';
        will-change: transform;
        background-size: 100% 100%;
        backface-visibility: hidden;
        transition: $menu-accordion-arrow-transition;
        @include svg-bg-icon(arrow-end, var(--kt-primary));
      }
    }

    &.showing:not(.menu-dropdown),
    &.show:not(.hiding):not(.menu-dropdown) {
      > .menu-link {
        .menu-arrow:after {
          @include menu-link-arrow-rotate(-90deg, 90deg);
        }
      }
    }
  }

  .menu-item-sub {
    border-radius: 0;
    box-shadow: none;

    .menu-title {
      text-transform: initial;
      color: var(--kt-black);
      margin-left: 4.2rem;
    }
  }

  .menu-wrapper {
    position: relative;
  }
}

.aside-close {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
}

// Menu & wrapper
.menu,
.menu-wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

// Sub menu
.menu-sub {
  display: none;
  margin: 0;
  list-style: none;
  flex-direction: column;
}

// Sub accordion
.menu-sub-accordion {
  display: none;

  .show:not(.menu-dropdown) > &,
  &.show {
    display: flex;
  }
}

// Sub indention
.menu-sub-indention {
  @include menu-sub-indention($menu-accordion-indention);

  // Active link
  .menu-item .menu-item .menu-link.active {
    margin-right: $menu-link-padding-x;
  }
}

// Responsive

// Menu animations
@keyframes menu-sub-dropdown-animation-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menu-sub-dropdown-animation-move-up {
  from {
    margin-top: #{$menu-dropdown-animation-move-offset};
  }

  to {
    margin-top: 0;
  }
}

@keyframes menu-sub-dropdown-animation-move-down {
  from {
    margin-bottom: #{$menu-dropdown-animation-move-offset};
  }

  to {
    margin-bottom: 0;
  }
}