.actions-wrapper {
  position: absolute;
  text-align: left;
  width: max-content;
  font-size: 1.6rem;
  top: 3.5rem;
  color: var(--kt-primary);
  border: 1px solid var(--kt-primary);
  border-radius: 1rem;
  z-index: 40;
  background-color: var(--kt-white);
  overflow: hidden;

  @include media-breakpoint-up(md) {
    right: 0;
    top: 4rem;
  }

  @include media-breakpoint-up(xl) {
    top: 7rem;
  }

  button:hover svg path {
    fill: var(--kt-white);
  }

  .action-svg-wrapper svg {
    width: 70%;
  }

  .order-action-button {
    font-weight: bold;
    padding: 1.7rem;
    width: 100%;
    display: flex;
    gap: 1.4rem;
    align-items: center;
    border-bottom: 0.05rem solid var(--kt-primary);

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
}

.date {
  &-icon {
    width: 3.4rem;
    height: 4.5rem;

    @include media-breakpoint-up(md) {
      width: 6.4rem;
      height: 7.5rem;
    }
  }

  &-info {
    border-radius: 3rem;

    @include media-breakpoint-up(md) {
      box-shadow: var(--hb-box-shadow);
      margin-left: -2rem;
    }
  }

  &-info-content {
    font-size: 1.2rem;
    font-weight: $font-weight-extra-bold;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      padding: 1.4rem 2.8rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 1.4rem 3.8rem;
    }
  }
}

.delivery-info svg {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  z-index: 10;

  @include media-breakpoint-up(md) {
    width: 8.5rem;
    height: 8.5rem;
    margin-top: 1rem;
  }
}

.order-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: var(--kt-secondary);

    svg {
      fill: var(--kt-secondary);
    }
  }
}

.subscription-action {
  .tooltip-content {
    min-width: 21rem;
    padding: 1.4rem;

    &::after {
      @include media-breakpoint-down(md) {
        left: 4rem;
      }
    }
  }
}

.subscription-action-btn {
  font-weight: $font-weight-base;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  background-color: var(--kt-primary);
  border-radius: 1.2rem;
  color: var(--kt-white);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  max-height: 2.8rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
    line-height: 2.25rem;
    padding: 1.1rem 2rem;
    max-height: 3.4rem;
  }

  svg {
    width: 1rem;
    height: 1rem;

    @include media-breakpoint-up(lg) {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
}

.order-info {
  padding: 2rem;
  border-radius: 3rem;

  @include media-breakpoint-up(lg) {
    padding: 3rem;
  }

  &-primary {
    background-color: var(--kt-primary-light);
    border: 0.3px solid #c9dcd1;

    .accordion-arrow {
      color: var(--kt-primary);
      background-color: var(--kt-primary-light);
      border: 0.3px solid var(--kt-primary-light);
    }
  }

  &-secondary {
    background-color: var(--kt-secondary-light);
    border: 0.3px solid #fef0e6;

    .accordion-arrow {
      color: var(--kt-secondary);
      background-color: var(--kt-secondary-light);
      border: 0.3px solid var(--kt-secondary-light);
    }
  }
}

.order-info-summary {
  &-date {
    font-size: 1.4rem;
    line-height: 1.75rem;
    font-weight: $font-weight-base;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &-number {
    color: #7c7c7c;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: 1.25rem;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      line-height: 1.75rem;
      font-weight: $font-weight-base;
    }
  }

  &-status {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-style: italic;
    font-weight: $font-weight-bold;
    padding: 0.4rem 0.8rem;
    border-radius: 2rem;
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 389px) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      line-height: 1.75rem;
      padding: 0.3rem 0.8rem;
      right: 2rem;
    }

    &-processed {
      color: var(--kt-white);
      background-color: #2a9890;
    }

    &-skipped {
      background-color: #faf0cd;
      right: -1.2rem;

      @include media-breakpoint-up(md) {
        right: 0;
      }
    }

    &-unprocessed {
      background-color: #efefef;
      right: -2.7rem;

      @include media-breakpoint-up(md) {
        right: -2rem;
      }
    }
  }
}

.order-calculation {
  font-size: 1.2rem;
  font-weight: $font-weight-base;
  line-height: 1.5rem;
  color: #545454;
  margin-right: 2.1rem;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    margin-right: 3.1rem;
    display: flex;
    justify-content: end;
  }
}
