.pricing-banner {
  max-width: 100%;
  margin-left: auto;
  margin-right: 0;

  @include media-breakpoint-up(lg) {
    max-width: calc(100% - 300px) !important;
    padding: 0 30px;
  }


  width: 100%;
  padding: 1rem;
  background-color: var(--kt-white);
  text-align: center;
  z-index: 1000;
  box-shadow: var(--hb-box-shadow);

  @include media-breakpoint-up(md) {
    padding: 1.5rem 5rem;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    display: flex;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      padding-left: 1rem;
      padding-right: 0.9rem;
    }
  }

  &-btn {
    background-color: var(--kt-secondary);
    color: var(--kt-white);
    border-radius: 9rem;
    font-weight: $font-weight-extra-bold;
    line-height: 3rem;
    box-shadow: var(--hb-box-shadow);
    font-size: 1.6rem !important;
    padding: 1.2rem 2rem;
    outline: none;
    border: none;
    font-weight: $font-weight-extra-bold;
    margin-bottom: 0.3rem;

    @include media-breakpoint-up(md) {
      padding: 1.2rem 3rem;
      font-size: 2rem;
    }
  }

  &-state {
    font-size: 1rem;
    color: var(--kt-light-600);
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }

  &-popup {
    position: absolute;
    background-color: var(--kt-white);
    padding: 2rem;
    width: 32rem;
    top: -16rem;
    left: -1rem;
    border-radius: 2rem;
    border: 1px solid var(--kt-primary);

    svg {
      transform: rotate(2deg);
    }

    span {
      position: absolute;
      top: 5.35rem;
      left: 0.5rem;
    }
  }

  .pricing-bar-wrapper {
    font-weight: $font-weight-extra-bold;
    font-size: 1.2rem;
    line-height: 3rem;
  }

  &-price {
    color: var(--kt-primary);
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 2rem;
    text-align: left;
    display: flex;
    gap: 0.5rem;

    .price-span {
      font-size: 2rem;
      color: var(--kt-black);
      margin-right: 0;

      @include media-breakpoint-up(lg) {
        font-size: 3rem;
        line-height: 2.8rem;
        margin-left: 1rem;
      }
    }
  }

  &-question-mark svg {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .pricing-bar {
    &-recipe {
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        width: 1.6rem;
        height: 1.6rem;
      }

      &-cg {
        background-color: var(--hb-product-cg);
      }

      &-gs {
        background-color: var(--hb-product-gs);
      }

      &-rt {
        background-color: var(--hb-product-rt);
      }
    }

    &-extra {
      font-size: 1.2rem;
      font-weight: $font-weight-extra-bold;
      line-height: 3rem;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }

    &-desktop-info {
      display: none;
      color: var(--kt-primary);
      font-weight: $font-weight-bold;
      line-height: 3rem;
      font-size: 1.6rem;
      margin-right: 1rem;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        display: inline-block;
      }
    }
  }
}