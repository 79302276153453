// TODO: remove unused scss and improve

.choose {
  border-radius: 0px 0px 30px 30px;
  display: flex;
  margin-top: auto;

  @include media-breakpoint-up(md) {
    background-color: var(--kt-white);
  }

  label {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 1.66;
    font-weight: $font-weight-bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 3rem 0;

    @include media-breakpoint-up(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    div {
      position: relative;
    }

    span {
      vertical-align: middle;
    }
  }

  .mischling-text {
    font-size: 1.6rem;
    margin-left: -5px;
    vertical-align: baseline;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: no-drop;
    height: 0;
    width: 0;

    &[type='radio'],
    &[type='checkbox'] {
      ~ .checkmark {
        position: absolute;
        top: 50%;
        cursor: no-drop;
        left: 0;
        transform: translateY(-50%);
        height: 3rem;
        width: 3rem;
        border: 3px solid var(--kt-secondary);

        &:after {
          content: '';
          position: absolute;
          display: none;
        }

        &-right {
          left: auto;
          right: 0;
        }
      }

      &:checked {
        ~ .checkmark {
          &:after {
            display: block;
          }
        }
      }
    }

    &[type='checkbox'] {
      ~ .checkmark {
        border-radius: 4px;

        &:after {
          left: 0.8rem;
          top: 0;
          width: 1rem;
          height: 1.8rem;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &-green {
          border: 3px solid var(--kt-primary);
          height: 2.2rem;
          width: 2.2rem;

          &:after {
            left: 0.4rem;
            height: 1.2rem;
            width: 0.8rem;
            top: 0;
          }
        }
      }
    }

    &[type='radio'] {
      ~ .checkmark {
        border-radius: 50%;

        &:after {
          top: 0.7rem;
          left: 0.7rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }

  input:checked {
    ~ .checkmark {
      background-color: var(--kt-secondary);
    }

    ~ .checkmark-green {
      background-color: var(--kt-primary);
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &-state {
    &::before {
      content: ' ';
    }
  }
}