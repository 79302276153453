.payment-wrapper {
  border-radius: 2rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.payment {
  padding: 4rem 0;
  position: relative;
  min-height: 21rem;

  @include media-breakpoint-between(xl, xxl) {
    padding: 4rem 0;
  }

  &-active {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 2.3rem;
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 2rem;
    padding: 0 3rem;

    @include media-breakpoint-between(xl, xxl) {
      margin-bottom: 1.3rem;
      padding: 0 2rem;
    }

    svg {
      width: 3.5rem;
    }
  }

  &-active-paypal {
    svg {
      width: 6.5rem;
    }
  }

  &-expiration {
    font-size: 1.6rem;
    font-weight: $font-weight-base;
    line-height: 2rem;
    margin-bottom: 4.3rem;
    padding: 0 3rem;

    @include media-breakpoint-up(md) {
      max-width: 25rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include media-breakpoint-between(xl, xxl) {
      margin-bottom: 2.3rem;
      padding: 0 2rem;
    }
  }

  &-add-payment {
    color: var(--kt-primary);
    background-color: var(--kt-primary-light);
    border-top: 0.1rem solid var(--kt-primary-light);
    padding: 1.7rem 1.1rem;
    border-radius: 0 0 1rem 1rem;
    font-size: 1.4rem;
    font-weight: $font-weight-base;
    width: 100%;
    line-height: 1.7rem;

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }

    @include media-breakpoint-between(xl, xxl) {
      padding: 1.7rem 0.2rem;
    }
  }

  &-dropdown {
    position: relative;
    padding: 0 1.9rem;

    @include media-breakpoint-between(xl, xxl) {
      margin-left: 0;
      width: 100%;
      padding: 0 1rem;
    }

    &-button {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      max-height: 4rem;
      cursor: pointer;
      padding: 1.3rem 2.2rem;
      font-weight: $font-weight-base;
      font-size: 1.6rem;
      line-height: 2.6rem;
      border: 0.1rem solid #9dc1ad;
      border-radius: 3rem;
      color: var(--kt-light-inverse);

      @include media-breakpoint-between(xl, xxl) {
        padding: 0.8rem;
        min-width: 20rem;
      }
    }
  }
}