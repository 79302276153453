.condolence {
  &-headline {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    max-height: 11rem;
    background-color: #ffa79433;

    @include media-breakpoint-up(md) {
      max-height: none;
    }
  }

  &-title,
  &-subtitle {
    max-width: 19.1rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
      line-height: 2.75rem;
    }
  }

  &-subtitle {
    margin-bottom: 1.6rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.4rem;
      max-width: 29.5rem !important;
    }
  }

  &-message {
    font-weight: $font-weight-base;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.25rem;
      max-width: 43rem;
    }
  }

  &-company {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: $font-family-caveat !important;
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 3rem;
    }
  }

  &-heart svg {
    width: 7.4rem;
    height: 8.6rem;
    padding: 1.2rem 0;

    @include media-breakpoint-up(md) {
      padding: 0 4rem;
      margin-left: 0;
      width: 15rem;
    }
  }

  &-content {
    padding: 2rem 2rem 3rem 2rem;
    @include media-breakpoint-up(md) {
      padding: 4rem;
    }
  }
}
