.menu-panel {
  position: absolute;
  bottom: -0.5rem;
  transform: translateY(100%);
  background-color: var(--kt-white);
  box-shadow: var(--hb-box-shadow);
  border-radius: 1rem;
  z-index: 20;

  @media only screen and (min-width: 1280px) and (max-width: 1535px) {
    width: 100%;
  }
}

.menu-panel-payment {
  bottom: 3.5rem !important;
  max-width: 23rem;
  left: 1.9rem;
  white-space: nowrap;

  .menu-panel-btn {
    max-width: 23rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1535px) {
    max-width: 20rem;
    left: 1rem;
  }
}

.fill-black {
  fill: var(--kt-black);
}

.menu-dropdown {
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
}

.menu-panel-link,
.menu-panel-btn {
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--kt-black);
  font-weight: $font-weight-base;
}

.menu-panel-btn {
  font-weight: $font-weight-base;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: left;
  padding: 0.75rem 2rem;
  cursor: pointer;
  border-radius: 2rem;
  display: block;
  width: -webkit-fill-available;

  &:hover {
    @include media-breakpoint-up(lg) {
      color: var(--kt-primary);
    }
  }

  &-active {
    font-weight: $font-weight-bold;
    color: var(--kt-primary);
    cursor: not-allowed;
  }

  &:last-child {
    border-radius: 0;
  }
}

.menu-panel-link {
  &-active {
    color: var(--kt-secondary);
  }
}

.menu-panel-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--kt-secondary);
  font-weight: $font-weight-base;
  margin-top: 2rem;
}

.add-shipping-address {
  color: var(--kt-primary);
  background-color: var(--kt-primary-light);
  border-top: 1px solid var(--kt-primary-light);
  padding: 1.7rem 4.1rem;
  border-radius: 0 0 1rem 1rem;
  font-size: 1.4rem;
  font-weight: $font-weight-base;
  width: 100%;
  line-height: 1.7rem;
  &:hover {
    background-color: var(--kt-primary);
    color: var(--kt-white);
  }
}

.update-shipping-address-wrapper {
  position: relative;
  margin: 1.5rem 0;
  margin-left: 3.6rem;

  @include media-breakpoint-up(xl) {
    margin-left: 3.6rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1535px) {
    margin-left: 0;
  }
}
.update-shipping-address {
  display: flex;
  justify-content: center;
  min-width: 25.2rem;
  gap: 5px;
  align-items: center;
  max-height: 4rem;
  cursor: pointer;
  padding: 1.3rem 2.2rem;
  font-weight: $font-weight-base;
  font-size: 1.6rem;
  line-height: 2.6rem;
  border: 1px solid #9dc1ad;
  border-radius: 3rem;
  color: var(--kt-light-inverse);

  @media only screen and (min-width: 1280px) and (max-width: 1535px) {
    min-width: 25.7rem;
  }

  svg {
    width: 1.5rem;
    height: 1rem;
  }
}
