.subscription-reactivation-modal {
  padding: 3rem 2rem;

  @include media-breakpoint-up(md) {
    padding: 4rem;
    display: flex;
    gap: 6rem;
  }

  &-customer {
    padding: 2rem 3rem;
    box-shadow: 0px 0px 5px 0px rgba($black, 0.15);
    border-radius: 2rem;
    margin-bottom: 2.4rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      box-shadow: none;
      padding: 0;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 2.4rem;
    }
  }

  li {
    color: #a7a2a2;
    font-size: 1.4rem;
    font-weight: $font-weight-base;
    line-height: 1.75rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  ul {
    margin-bottom: 2rem;
    span {
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: var(--kt-black);
    }
  }

  &-address-dropdown,
  &-payment-dropdown {
    display: flex;
    color: var(--kt-black);
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--kt-black);
    padding: 1.4rem 2rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: $font-weight-base;
    max-height: 4.4rem;
    width: 25.2rem;

    @include media-breakpoint-up(md) {
      gap: 0.8rem;
      padding: 1.5rem 2rem;
    }

    svg {
      width: 1.5rem;
    }
  }

  &-payment-dropdown {
    border: 1px solid var(--kt-primary);
    color: var(--kt-primary);
  }

  .menu-panel-address {
    bottom: auto;
    top: -1rem;
    width: fit-content;

    @include media-breakpoint-up(md) {
      top: 12.5rem;
    }
  }

  .menu-panel-payment {
    left: 0;
    top: 5rem;
    z-index: 50;
  }

  &-payment {
    background: radial-gradient(
      90.16% 143.01% at 15.32% 21.04%,
      rgba(201, 220, 209, 0.2) 0%,
      rgba(130, 191, 171, 0.2) 77.08%,
      rgba(0, 118, 108, 0.2) 100%
    );
    padding: 2rem;
    border-radius: 2rem;

    @include media-breakpoint-up(md) {
      padding: 2rem 1.9rem 2.4rem 1.9rem;
    }

    .payment-active {
      padding: 0;
      svg {
        width: 6rem;
        height: 2.6rem;
        background-color: var(--kt-white);
        padding: 0.5rem;
        border-radius: 0.5rem;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    .payment-card {
      font-size: 2.2rem;
      font-weight: $font-weight-bold;
      line-height: 2.75rem;
      display: flex;
      justify-content: end;
      padding: 0;
      margin-bottom: 3rem;
      position: relative;

      .main-info {
        color: #00756c;
        position: absolute;
        z-index: 2;
      }

      .secondary-info {
        color: #ffffff;
        position: absolute;
        top: 0.12rem;
        z-index: 1;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 3.99rem;
        margin-right: 3.3rem;
      }
    }

    .payment-exp-info {
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        margin-right: 3.3rem;
      }

      p {
        color: var(--kt-primary);
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: 2rem;
      }
      span {
        font-weight: $font-weight-bold;
        font-size: 0.6rem;
        line-height: 2.6rem;
        color: var(--kt-primary);
        text-transform: uppercase;
      }
    }
  }

  .menu-panel-modal-payment {
    min-width: 25.2rem;
    bottom: 4.5rem !important;

    .menu-dropdown {
      background-color: var(--kt-white);
      border-radius: 1rem 1rem 0 0;
    }
  }
}
