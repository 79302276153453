.subscription-bonus {
  &-title {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: $font-weight-extra-bold;
    color: var(--kt-black);
    line-height: 1.75rem;
    margin-bottom: 1.6rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.1rem;
    }
  }
  &-item {
    display: flex;
    gap: 1rem;
    color: #333333;

    &-title {
      font-weight: $font-weight-bold;
      font-size: 1.4rem;
      line-height: 1.75rem;
      margin-bottom: 1.7rem;
    }

    &-description {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: $font-weight-base;
      max-width: 19rem;
    }

    &-price {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: $font-weight-extra-bold;
      background-repeat: no-repeat;
      background-size: contain;
      height: fit-content;
      max-width: fit-content;
      color: var(--kt-primary);
      padding: 0.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
        line-height: 1.75rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &-image {
    width: 6.4rem;
    height: 6.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf0cd;
    border-radius: 0.5rem;

    img {
      height: 5.3rem;
    }
  }
}
