.discount-wrapper {
  max-width: 34rem;

  &-label {
    position: absolute;
    padding: 0 0.1rem;
    margin-left: 3.3rem;
    color: var(--kt-primary);
    font-weight: $font-weight-bold;
  }

  &-input {
    padding: 1.2rem;
    margin-right: -2rem;
    border: 1px dashed var(--kt-primary);
    border-radius: 1.5rem;
  }

  &-text {
    color: var(--kt-black);
    text-transform: uppercase;
    background-color: #ffd580;
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    line-height: 1.25;
  }
}

.discount-wrapper-modal {
  position: relative;
  min-width: 38rem;
  min-height: 15rem;
  display: flex;
  align-items: center;
  padding: 8rem 2rem 3rem 2rem;
}

.discount-handler-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem;
  background-color: var(--kt-primary);
  color: var(--kt-white);
  font-weight: $font-weight-bold;
  border-radius: 0 1.5rem 1.5rem 0;
}

.discount-error-msg {
  color: var(--kt-secondary);
  font-weight: $font-weight-extra-bold;
  padding-top: 1rem;
}
