//
// Accordion
//

// Base
.accordion {
  .accordion-header {
    cursor: pointer;
    padding: 1.4rem 1.2rem;
    @include media-breakpoint-up(lg) {
      padding: 2rem 3rem !important;
    }
  }

  &-item {
    &:first-child {
      border-radius: 2rem 2rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 2rem 2rem;
    }
  }

  &-arrow {
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      width: 3.4rem;
      height: 3.4rem;
    }

    svg {
      width: 1rem;
      height: 0.7rem;

      @include media-breakpoint-up(lg) {
        width: 1.5rem;
        height: 1rem;
      }
    }
  }

  .accordion-body {
    padding: 0rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    .accordion-body {
      padding: 3rem;
    }

    .accordion-header {
      cursor: pointer;
      padding: 1.6rem 3rem;
    }
  }

  .collapse {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    visibility: visible;
    display: block;
    height: auto;

    &:not(.show) {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      height: 0;
      visibility: hidden;
      display: none;
    }
  }
}
