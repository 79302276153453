.cycle {
  border: 2px solid #c1c1c1;
  position: relative;
  background-color: var(--kt-primary-light);
  border-radius: 3.1rem;
  display: flex;
  flex-direction: column;
  height: inherit;

  @include media-breakpoint-up(md) {
    border: 1px solid #c1c1c1;
    padding-bottom: 0;
  }

  &.disabled {
    @include disabled-overlay;
  }

  .infos {
    height: 100%;
    margin: 0 1rem 0 1rem;

    @include media-breakpoint-up(xl) {
      margin: 0 2.1rem 0 2.1rem;
    }
  }

  &-active {
    background-color: var(--kt-primary);
    color: var(--kt-white);

    .infos {
      margin: 0 1rem 0 1rem;

      @include media-breakpoint-up(md) {
        border-bottom: 0.2rem solid var(--kt-white);
      }

      @include media-breakpoint-up(xl) {
        margin: 0 2.1rem 0 2.1rem;
      }
    }

    .more {
      color: var(--kt-white);

      &:hover {
        > span {
          color: var(--kt-white);
        }
      }
    }

    .choose {
      background-color: var(--kt-primary);
    }
  }

  .graphic {

    .calendar-main,
    .calendar-up,
    .calendar-bg,
    .calendar-num {
      fill: var(--kt-white);
    }

    .calendar-border,
    .calendar-main,
    .calendar-main-border {
      fill: var(--kt-primary);
    }

    .calendar-main-border {
      stroke: var(--kt-primary);
    }

    svg {
      width: 10rem;
      height: 10rem;
    }

    &-image {
      img {
        position: absolute;
        top: 5.5rem;
        right: 1rem;
        width: 7rem;
        height: 5rem;

        @include media-breakpoint-up(md) {
          width: 15rem;
          display: flex;
          justify-content: center;
          top: 0rem;
          left: 50%;
          transform: translateX(-50%);
          position: relative;
          height: 15rem;
          top: 2rem;
        }

        @include media-breakpoint-up(xl) {
          top: 0;
          width: 30rem;
          height: 15rem;
        }
      }
    }
  }

  &-active {
    border: 1px solid var(--kt-primary-light);

    .graphic {

      .calendar-up,
      .calendar-bg {
        fill: var(--kt-primary);
      }

      .calendar-border,
      .calendar-main-border {
        fill: var(--kt-white);
      }

      .calendar-main-border {
        stroke: var(--kt-white);
      }
    }
  }

  &-wrapper {
    img {
      border-radius: 3rem 3rem 0 0;
    }
  }

  &-content {
    padding: 2rem 0 2rem 0;

    @include media-breakpoint-up(md) {
      padding: 2.7rem 0 2.1rem 0;
    }

    &-title {
      font-weight: $font-weight-bold;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-size: 1.8rem;
      line-height: 2.3rem;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
        line-height: 4.5rem;
        margin-bottom: 2.5rem;
      }

      span {
        font-size: 2.2rem;
        font-weight: $font-weight-extra-bold;
        line-height: 2.8rem;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          font-size: 3.2rem;
          line-height: 4.5rem;
          margin-left: 0.9rem;
        }
      }
    }
  }
}

.cycle-disabled-wrapper {
  background-color: var(--kt-light);
}

.cycle-disabled-item {
  font-weight: $font-weight-bold;

  h4 {
    font-size: 1.8rem;
    color: var(--kt-danger);
    line-height: 2.4rem;
    margin-bottom: 0.7rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.2rem;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-bottom: 1.2rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  span {
    font-size: 1.3rem;
    line-height: 1.7rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }
}