.recipe {
  border-radius: 3rem;
  border: 1px solid #c1c1c1;

  .recipe-disabled {
    border: 1px solid #c1c1c1 !important;
  }

  &-cg {
    border-color: var(--hb-product-cg);
  }

  &-gs {
    border-color: var(--hb-product-gs);
  }

  &-rt {
    border-color: var(--hb-product-rt);
  }

  &-title {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
    line-height: 2.5rem;
    padding-top: 3.4rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 2.3rem;
      padding-top: 0;
    }
  }

  &-wrap {
    border-radius: 2.5rem 2.5rem 0 0;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
      align-items: stretch;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &-description {
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    line-height: 2rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }
  }

  &-underline {
    &-cg {
      color: var(--hb-product-cg);
    }

    &-gs {
      color: var(--hb-product-gs);
    }

    &-rt {
      color: var(--hb-product-rt);
    }
  }

  &-image-wrapper {
    border-radius: 0 3rem 3rem 0;

    @include media-breakpoint-up(md) {
      border-radius: 3rem 3rem 0 0;
    }

    @include media-breakpoint-up(lg) {
      border-radius: 0 3rem 3rem 0;
    }

    @include media-breakpoint-up(xl) {
      border-radius: 3rem 3rem 0 0;
    }
  }

  &-image {
    border-radius: 0;
    width: 20rem;

    @include media-breakpoint-up(md) {
      border-radius: 2.9rem 2.9rem 0 0;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      border-radius: 0;
      width: 20rem;
    }

    @include media-breakpoint-up(xl) {
      border-radius: 2.9rem 2.9rem 0 0;
      width: 100%;
    }
  }

  &-button {
    color: var(--kt-light-inverse);
    font-weight: $font-weight-base;
    font-size: 1.6rem;
    line-height: 2rem;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    margin: auto auto 4.5rem auto;

    @include media-breakpoint-up(md) {
      line-height: 2.3rem;
    }
  }

  &-select-btn {
    font-weight: $font-weight-bold;
    border-radius: 3rem;
    display: flex;
    max-width: 23rem;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.9rem;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    right: 18%;
    transform: translateX(-50%) translateY(-50%);

    @include media-breakpoint-up(md) {
      position: relative;
      top: -3rem;
      left: auto;
      right: 0;
      transform: none;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translateX(-50%) translateY(-50%);
    }

    @include media-breakpoint-up(xl) {
      position: relative;
      top: -3rem;
      left: auto;
      right: 0;
      transform: none;
      margin: 0 auto;
    }
  }

  &-input-wrapper {
    padding: 1rem 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 1rem 2.5rem;
    }

    input[type='checkbox'] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      opacity: 0;
      z-index: 5;
    }

    &::before {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url('data:image/svg+xml,<svg  enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"/></g></svg>');
      background-repeat: no-repeat;
      background-size: 2rem;
      margin-right: 4px;
      z-index: 1;
    }

    @include media-breakpoint-up(md) {
      &:hover {
        &::before {
          background-image: url('data:image/svg+xml,<svg enable-background="new 0 0 512.235 512.235" height="512" viewBox="0 0 512.235 512.235" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m340.502 258.514c-1.32-1.321-1.32-3.471 0-4.792l161.257-161.336c13.789-13.794 13.789-36.24 0-50.035l-31.968-31.984c-6.682-6.686-15.566-10.367-25.016-10.367s-18.335 3.681-25.017 10.366l-161.257 161.338c-.816.816-1.747.988-2.383.988s-1.567-.171-2.383-.988l-161.258-161.338c-6.682-6.685-15.566-10.366-25.017-10.366s-18.334 3.681-25.016 10.366l-31.968 31.985c-13.789 13.795-13.789 36.241 0 50.035l161.257 161.336c1.32 1.321 1.32 3.471 0 4.792l-161.257 161.336c-13.788 13.795-13.788 36.24 0 50.035l31.968 31.984c6.682 6.685 15.566 10.366 25.016 10.366s18.335-3.681 25.017-10.366l161.257-161.336c.816-.817 1.746-.988 2.383-.988s1.566.171 2.383.989l161.257 161.336c6.682 6.685 15.566 10.366 25.017 10.366s18.334-3.681 25.016-10.366l31.968-31.984c13.788-13.794 13.788-36.24 0-50.035z"/></svg>');
          background-size: 1.4rem;
          margin-left: 2px;
          margin-top: 2px;
          z-index: 1;
        }

        .recipe-btn-text {
          position: relative;

          > span {
            opacity: 0;
          }

          &::after {
            content: 'Entfernen';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: transparent;
            z-index: 0;
          }
        }
      }
    }
  }

  &-input-wrapper-disabled {
    &::before {
      display: none !important;
    }

    &:hover {
      &::before {
        display: none !important;
      }
    }
  }

  &-input {
    width: 2.4rem;
    height: 2.4rem;

    margin-right: 1.5rem;

    &-cg {
      accent-color: var(--hb-product-cg);
    }

    &-gs {
      accent-color: var(--hb-product-gs);
    }

    &-rt {
      accent-color: var(--hb-product-rt);
    }
  }
}

.slick-slider {
  margin-bottom: 3rem;
}

.slick-dots li button:before {
  color: #e45c41 !important;
  font-size: 1rem !important;
}

.cool-fact {
  color: #000;
  width: 30%;
  min-width: 330px;
  position: relative;
}

.label {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.recipe-modal-input[type='checkbox'] {
  display: none;

  &:checked ~ div.label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:checked ~ p {
    height: fit-content;
    color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
  }
}

.recipe-modal-label[for='toggle'] {
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

p.answer {
  color: transparent;
  width: 100%;
  height: 0;
  margin: 0;
}

.arrow-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(135deg);
  transition: transform 0.3s ease;
}

.arrow-icon.open {
  transform: rotate(-45deg);
}

.aktuelle-bestellung {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  text-transform: none;
  cursor: pointer;

  &:hover {
    font-weight: $font-weight-extra-bold;
  }

  svg {
    width: 1.4rem;
    height: 0.8rem;
    color: var(--kt-primary);

    @include media-breakpoint-up(md) {
      width: 2rem;
      height: 1.2rem;
    }
  }
}
