@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast {
  border-radius: 2rem !important;
  padding: 1.5rem;
  margin: 0 !important;
  height: 7.2rem;

  @include media-breakpoint-up(md) {
    padding: 1.8rem 1.8rem 2.4rem 1.8rem;
    height: 8.5rem;
  }
}

.Toastify__toast-container {
  margin-bottom: 6rem !important;
  width: min-content;
  font-size: 1.6rem;
  margin-left: 1.5rem;
  line-height: 2.2rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

.Toastify__toast-icon {
  margin-inline-end: 0 !important;
}

.Toastify__toast-body {
  padding: 0 !important;
  margin-right: 5rem;

  @include media-breakpoint-up(md) {
    margin-right: 4.8rem;
  }
}

.Toastify__toast-body > div:last-child {
  min-width: 21rem;
  color: var(--kt-white);
  margin-left: 2.7rem;

  @include media-breakpoint-up(md) {
    margin-left: 4.1rem;
    min-width: 23rem;
  }
}

.toastify-hb {
  &-success {
    background: var(--kt-primary) !important;

    .Toastify__progress-bar-theme--light {
      background: var(--kt-primary-light) !important;
    }

    .Toastify__toast-icon {
      svg {
        color: var(--kt-white);
        width: 3rem;
        height: 3rem;

        @include media-breakpoint-up(md) {
          width: 3.6rem;
          height: 3.6rem;
        }
      }
    }
  }

  &-error {
    background: var(--kt-secondary) !important;
    .Toastify__progress-bar-theme--light {
      background: var(--kt-secondary-light) !important;
    }

    .Toastify__toast-icon {
      svg {
        color: var(--kt-secondary);
        width: 3.6rem;
        height: 3.6rem;

        @include media-breakpoint-up(md) {
          width: 4.6rem;
          height: 4.6rem;
        }
      }
    }
  }

  &-success,
  &-error {
    font-weight: $font-weight-bold;

    .Toastify__close-button--light {
      color: var(--kt-white) !important;
      opacity: 1 !important;
    }
  }
}

.Toastify__close-button {
  svg {
    width: 1rem;
    height: 1rem;

    @include media-breakpoint-up(md) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
