.last-chance {
  &-headline {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background-color: var(--kt-light-active);
    border-radius: 3rem 3rem 0 0;
    padding: 4.4rem 2rem;
    max-height: 11rem;

    @include media-breakpoint-up(md) {
      border-radius: 3rem 0 0 3rem;
      padding: 0 2.1rem;
      max-height: unset;
    }
  }

  &-title {
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.5rem;

    span {
      font-weight: $font-weight-bold;
      font-size: 1.8rem;
      line-height: 2.25rem;
    }
  }

  &-subtitle {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
      line-height: 2.75rem;
    }
  }

  &-subtitle {
    margin-bottom: 1.6rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
  }

  &-message {
    font-weight: $font-weight-base;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2.4rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      margin-bottom: 3rem;
      line-height: 2.25rem;
      max-width: 35rem;
    }
  }

  &-btn {
    button {
      font-weight: $font-weight-bold;
      white-space: nowrap;
      font-size: 1.8rem;
      line-height: 2.25rem;
      color: var(--kt-black);
      padding: 1.3rem 6.7rem;
      border-radius: 3rem;

      @include media-breakpoint-up(md) {
        padding: 1.8rem 6.4rem;
      }
    }

    &-skip {
      background-color: #faf0cd;
      margin-bottom: 1rem;

      &:hover {
        background-color: #f7e9b8;
      }
    }

    &-pause {
      border: 0.2rem solid #faf0cd;
    }
  }

  &-content {
    padding: 2rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }
  }

  .intro-cancel-bulb {
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }

    svg {
      width: 6rem;
    }
    h5 {
      font-size: 1.8rem;
      font-weight: $font-weight-base;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }

    .intro-cancel-content-description {
      font-size: 1.4rem;
      line-height: 1.75rem;
    }
  }
}
