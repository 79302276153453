.raf {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    margin-left: 1rem;
  }

  &-text {
    font-weight: $font-weight-base;
    font-size: 1.6rem;
    line-height: 2rem;

    display: none;

    // @media only screen and (min-width: 1299px) and (max-width: 1535px) {
    //   display: inline-block;
    //   min-width: 20rem;
    // }

    @media only screen and (min-width: 1536px) {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  &-link {
    font-weight: $font-weight-bold;
    font-size: 1.4rem;
    line-height: 17.5px;
    font-weight: $font-weight-bold !important;
    text-decoration: underline;
    text-underline-offset: 4px;
    color: var(--kt-primary);
    min-width: 17rem;

    @media only screen and (min-width: 767px) {
      font-size: 1.6rem;
      line-height: 2rem;
      min-width: 19rem;
    }
  }
}
