.cancellation-reason {
  &-title {
    background-color: var(--kt-light-active);
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    line-height: 2.25rem;
    text-transform: uppercase;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 3.2rem 4.4rem;
    border-radius: 3rem 3rem 0 0;
    min-width: 30rem;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
      line-height: 2.75;
      padding: 3rem;
    }
  }

  &-form {
    padding: 2rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 3rem 4rem 4rem 4rem;
    }

    &-label label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      margin-bottom: 1.1rem;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;

      @include media-breakpoint-up(md) {
        gap: 1rem;
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
        line-height: 2.25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-radio {
      width: 2.2rem;
      height: 2.2rem;
      display: inline-block;
      background-size: cover;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem 3rem 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: $font-weight-base;

    @include media-breakpoint-up(md) {
      padding: 0rem 4rem 4rem 4rem;
      font-size: 1.8rem;
      line-height: 2.25rem;
    }
  }

  &-missing {
    font-size: 1.4rem;
    font-weight: $font-weight-base;
    line-height: 1.75rem;
    color: var(--kt-secondary);
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-top: -1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 4rem;
      margin-left: 4rem;
    }
  }
}
