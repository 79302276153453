@import 'intro-cancel';
@import 'benefit';
@import 'reasons';
@import 'offer';
@import 'condolence';
@import 'last-chance';

.cancellation-offer-action-continue-btn {
  padding: 1.4rem 9.4rem;
  color: var(--kt-white);
  background-color: var(--kt-secondary);
  font-size: 1.8rem;
  line-height: 2rem;
  border-radius: 3rem;
  width: 100%;
  margin-bottom: 1rem;
  white-space: nowrap;
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 4.8rem;

  @include media-breakpoint-up(md) {
    padding: 1.8rem 10.3rem;
    max-height: 6rem;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1.2rem;
  }
}

.cancellation-offer-action-stop {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-base;
  font-size: 1.4rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 4rem;
  }

  button {
    text-decoration: underline;
    color: var(--kt-primary);
  }

  span {
    margin: 0 0.5rem;
  }
}

.cancellation-flow-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: $font-weight-base;

  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

.cancel-thank-you {
  svg {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1.6rem auto;

    @include media-breakpoint-up(md) {
      width: 7rem;
      height: 7rem;
    }
  }

  &-reactivate {
    @include media-breakpoint-up(md) {
      font-size: 1.6rem !important;
      line-height: 2rem !important;
    }
  }

  &-link {
    font-weight: $font-weight-base;
    font-size: 1.2rem;
    line-height: 1.5rem;

    a {
      color: var(--kt-primary);
      text-decoration: underline;
    }
  }
}
