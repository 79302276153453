.footer {
  .footer-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .menu-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media-breakpoint-down(xl) {
      background: var(--kt-white);
    }

    &:first-child {
      border-radius: 1.5rem 1.5rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 1.5rem 1.5rem;
    }
  }

  .menu-list {
    padding: 2rem;

    @include media-breakpoint-down(xl) {
      display: none;

      &.expanded {
        display: block;
      }
    }
  }

  .menu-item {
    button {
      width: 100%;
      padding: 2rem;
      display: flex;
      justify-content: flex-start;
    }
  }

  .footer-logo {
    max-width: 10rem;
  }
}

.scroll-top-btn {
  position: absolute;
  right: 1rem;
  bottom: 2.5rem;
}

.cloud {
  max-width: 23.5rem;
  height: auto;
  display: block;
  background-color: var(--kt-light);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1.4);

    @include media-breakpoint-up(lg) {
      transform: scale(1.2);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
