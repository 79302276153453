.intro-cancel {
  @include media-breakpoint-up(md) {
    display: flex;
  }

  &-header {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(233, 233, 233, 1) 70%);
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 3rem 3rem 0 0;
    max-height: 11rem;

    @include media-breakpoint-up(md) {
      border-radius: 3rem 0 0 3rem;
      flex-direction: column-reverse;
      align-items: center;
      gap: 1rem;
      max-width: 23rem;
      background: linear-gradient(180deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
      padding-top: 10rem;
      max-height: none;
    }
  }

  &-dog,
  &-dog-goodbye {
    z-index: 10;
    margin-left: 7rem;

    @include media-breakpoint-up(md) {
      margin-left: 1rem;
      margin-bottom: -2rem;
    }
  }

  &-dog {
    width: 13.4rem;
    position: relative;
    top: -2rem;
    height: 12.6rem;

    @include media-breakpoint-up(md) {
      width: 21rem;
      height: 19.7rem;
      top: 0;
    }
  }

  &-dog-goodbye {
    height: 14.6rem;
    margin-top: -3rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 0;
      height: 28.5rem;
      margin-bottom: -4.9rem;
    }
  }

  &-bubble {
    width: 16rem;
    position: relative;
    z-index: 0;
    right: 4.8rem;
    top: -5.5rem;

    @include media-breakpoint-up(md) {
      width: 18.7rem;
      right: 0;
      top: 0;
    }
  }

  &-bubble-comment {
    top: -5rem;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      top: 0;
    }
  }

  &-bulb {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.9rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0.8rem;
    }

    svg {
      width: 6rem;

      @include media-breakpoint-up(md) {
        width: 7.6rem !important;
        gap: 1.6rem;
      }
    }
  }

  &-content {
    padding: 2rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4.8rem 4rem 4rem 4rem;
    }

    &-comment {
      min-height: 10rem;
      width: 100%;
      border-radius: 2rem;
      padding: 1.5rem;
      border: 1px solid #c1c1c1;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
      color: #c1c1c1;
      margin-bottom: 2.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }

      &::placeholder {
        color: #c1c1c1;
        font-weight: $font-weight-bold;
      }

      &:focus {
        color: black;
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: $font-weight-bold;
      line-height: 2.25rem;
      margin-bottom: 1.6rem;
      max-width: 30rem;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
        line-height: 2.75rem;
        margin-bottom: 2.4rem;
      }
    }

    h5 {
      font-weight: $font-weight-base;
      font-size: 1.8rem;
      line-height: 2.25rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
        line-height: 2.75rem;
        margin-bottom: 1.6rem;
      }
    }

    p {
      font-weight: $font-weight-base;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 2.4rem;
      max-width: 33rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        line-height: 2.25rem;
        max-width: none;
      }
    }

    &-text {
      margin-bottom: 1.6rem !important;

      @include media-breakpoint-up(md) {
        max-width: 40rem !important;
      }
    }

    &-description {
      font-size: 1.4rem;
      font-weight: $font-weight-base;
      line-height: 1.75rem;
      max-width: 26rem;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem !important;
        line-height: 2.25rem !important;
        max-width: 34rem !important;
      }
    }
  }

  &-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-pause {
      border-radius: 3rem;
      background-color: #faf0cd;
      padding: 1.3rem 10.7rem;
      font-weight: $font-weight-bold;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 1rem;
      white-space: nowrap;

      &:hover {
        background-color: #f7e9b8;
      }

      @include media-breakpoint-up(md) {
        padding: 1.8rem 15.1rem;
        font-size: 2rem;
        line-height: 2.5rem;
        max-height: 6rem;
        margin-bottom: 1.2rem;
      }
    }

    &-cancel {
      background-color: var(--kt-light-active);

      &:hover {
        background-color: #c1c1c1;
      }
    }

    &-continue {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.6rem;
      line-height: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 2.25rem;
      }
    }
  }
}
