.pension {
  position: relative;
  background-color: var(--kt-primary-light);
  border-radius: 3.1rem;
  display: flex;
  flex-direction: column;
  border: 2px solid #c1c1c1;

  @include media-breakpoint-up(md) {
    border: 1px solid #c1c1c1;
  }

  &-opacity-disabled {
    background-color: rgb(228, 237, 231, 0.8);
    .pension-disabled-item {
      opacity: 1 !important;
    }
  }

  &.disabled {
    @include disabled-overlay;
  }

  .pension-wrapper {
    height: 100%;
    margin: 0 1rem 0 1rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 3rem;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 2.1rem 0 2.1rem;
    }
  }

  &-active {
    background-color: var(--kt-primary);
    color: var(--kt-white);

    .pension-wrapper {
      margin: 0 1rem 0 1rem;

      @include media-breakpoint-up(md) {
        border-bottom: 0.2rem solid var(--kt-white);
      }

      @include media-breakpoint-up(xl) {
        margin: 0 2.1rem 0 2.1rem;
      }
    }

    .more {
      color: var(--kt-white);

      &:hover {
        > span {
          color: var(--kt-white);
        }
      }
    }

    .choose {
      background-color: var(--kt-primary);
    }
  }

  &-wrapper {
    padding: 7rem 0rem 0rem 1rem;

    @include media-breakpoint-up(md) {
      padding: 4.5rem 2.2rem;
    }

    .description-list {
      padding: 0 1rem;
    }
  }

  .pension-wrapper-disabled {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 2rem 1rem;
    }

    @include media-breakpoint-up(md) {
      padding: 4rem 1rem;
    }
  }

  &-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-weight: $font-weight-extra-bold;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    padding-top: 2rem;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin: 0 3.2rem;
      padding-top: 4rem;
    }
  }

  &-disabled-item {
    text-align: center;
    color: #ff0000;
    font-weight: $font-weight-bold;
    padding: 1rem 3.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      padding: 0;
      text-align: left;
      display: block;
      align-items: left;
    }

    svg {
      margin-bottom: 0.8rem;
    }

    h4 {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 0.7rem;

      @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 1.2rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    span {
      font-size: 1.3rem;
      line-height: 1.7rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }
}

.banner {
  position: absolute;
  z-index: 1;
  max-width: 22rem;
  background: #e05b40;
  color: #fff;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
}

.banner:after {
  content: '';
  position: absolute;
  transform: translate(100%);
  width: 0;
  height: 0;
  bottom: -1rem;
  border-top: 1rem solid #e05b40;

  @include media-breakpoint-up(lg) {
    right: -1rem;
    left: auto;
  }
}

.pension .banner {
  left: -1rem;
  right: auto;
  border-radius: 0 2rem 2rem 0;
  top: 6rem;
  padding: 0.8rem 1rem;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 0;

  @include media-breakpoint-up(lg) {
    right: -1rem;
    left: auto;
    border-radius: 2rem 0 0 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 1rem 3rem;
  }
}

.pension .banner:after {
  border-left: 1rem solid transparent;
  border-right: none;
  right: auto;
  left: -1rem;

  @include media-breakpoint-up(lg) {
    content: '';
    position: absolute;
    transform: translate(100%);
    width: 0;
    left: auto;
    right: 1rem;
    border-right: 1rem solid transparent;
    border-left: none;
  }
}

.graphic {
  svg {
    width: 10rem;
    height: 10rem;
  }

  &-image {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: 15rem;
    }

    @include media-breakpoint-up(xl) {
      width: 25rem;
    }

    img {
      position: absolute;
      top: 5.5rem;
      right: 1rem;
      width: 7rem;
      height: auto;

      @include media-breakpoint-up(lg) {
        width: 100%;
        display: flex;
        justify-content: center;
        top: 0rem;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        height: auto;
        top: 2rem;
      }

      @include media-breakpoint-up(xl) {
        top: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  &-image-disabled {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      padding-top: 4.5rem;
    }

    @media screen and (min-width: 1280px) and (max-width: 1535px) {
      padding-top: 3.8rem;
      width: 32rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: 1rem;
      width: 33rem;
    }
  }
}
