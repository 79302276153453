.supplements {
  text-align: center;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    padding: 3rem 1.5rem;
    border-radius: 3rem;
  }

  &-title {
    font-weight: $font-weight-base;
    font-size: 1.8rem;
    line-height: 22.5px;
    text-transform: uppercase;
    color: var(--kt-primary);

    @include media-breakpoint-up(md) {
      font-size: 1.7rem;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    span {
      font-weight: $font-weight-bold;
    }
  }

  &-link {
    background-color: var(--kt-secondary);
    padding: 1.4rem 6.9rem;
    font-weight: $font-weight-extra-bold;
    font-size: 1.6rem;
    line-height: 2rem;
    border-radius: 3rem;
    width: fit-content;
    margin: 0 auto;
    box-shadow: var(--hb-box-shadow);

    @include media-breakpoint-up(md) {
      padding: 1.4rem 7.5rem;
    }
  }
}

.supplement-item {
  text-align: left;
  border-radius: 2rem;
  padding: 2.7rem 2rem;

  @include media-breakpoint-up(md) {
    padding: 3.2rem 3rem;
  }

  @media only screen and (min-width: 400px) and (max-width: 768px) {
    padding: 3.2rem 4rem;
  }

  @media only screen and (min-width: 870px) and (max-width: 1024px) {
    padding: 3.2rem 6rem;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 3.2rem 1.5rem;
  }

  @media only screen and (min-width: 1535px) {
    padding: 3.2rem 6rem;
  }

  &-text {
    max-width: 18rem;

    @media only screen and (min-width: 1024px) {
      max-width: 18rem;
    }

    span {
      margin-bottom: 1.5rem;
      font-style: italic;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    h3 {
      font-size: 1.4rem;
      font-weight: $font-weight-extra-bold;
      line-height: 17.5px;
      padding-top: 1.5rem;

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
      }
    }
  }

  &-img {
    position: absolute;
    right: 2rem;
    bottom: -1rem;

    @media only screen and (min-width: 400px) and (max-width: 768px) {
      right: 4rem;
    }

    @media only screen and (min-width: 870px) and (max-width: 1023px) {
      right: 6rem;
    }

    @media only screen and (min-width: 1535px) {
      right: 6rem;
    }
  }
}

.swiper-slide:not(.swiper-slide-active) .supplement-item {
  transition: all 0.3s ease-in 0.25s;
}

.supplement-slider-wrapper {
  max-width: calc(100vw - 4rem);
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 2.2rem;
  }

  .swiper-wrapper {
    margin: 4rem 0.5rem 2.5rem 0.5rem;
    display: flex;
    align-items: flex-end;
    min-height: 15rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem 0 2.9rem 0;
    }

    @include media-breakpoint-up(xl) {
      margin: 4rem 0.5 2.9rem 0.5;
      min-height: 16rem;
    }
  }

  .supplement-slider-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    font-size: 0;

    .swiper-pagination-bullet-active {
      background-color: var(--kt-primary) !important;
      opacity: 1 !important;
    }

    .swiper-pagination-bullet {
      background-color: var(--kt-primary);
      opacity: 0.5;
      width: 0.6rem;
      height: 0.6rem;
      margin: 0 !important;
    }
  }
}

.custom-swiper-button-next,
.custom-swiper-button-prev {
  cursor: pointer;
  background: none;
  width: 34px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-swiper-button-prev {
  transform: rotate(180deg);
}

.custom-swiper-button-next {
  display: none;
  background-image: url('data:image/svg+xml,%3Csvg width="34" height="16" viewBox="0 0 34 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.389558 8.91331L0.390755 8.91454L7.33047 15.6242C7.85036 16.1269 8.69127 16.125 9.20877 15.6198C9.72621 15.1147 9.72421 14.2978 9.20432 13.7951L4.54499 9.29028L32.6719 9.29028C33.4054 9.29028 34 8.7126 34 7.99996C34 7.28731 33.4054 6.70964 32.6719 6.70964L4.54506 6.70963L9.20426 2.20486C9.72415 1.70215 9.72614 0.885178 9.2087 0.38008C8.6912 -0.125145 7.85023 -0.126886 7.3304 0.375694L0.390687 7.08538L0.389493 7.0866C-0.130668 7.59099 -0.129009 8.4106 0.389558 8.91331Z" fill="%23C9DCD1"%3E%3C/path%3E%3C/svg%3E');

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.custom-swiper-button-prev {
  background-image: url('data:image/svg+xml,%3Csvg width="34" height="16" viewBox="0 0 34 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.389558 8.91331L0.390755 8.91454L7.33047 15.6242C7.85036 16.1269 8.69127 16.125 9.20877 15.6198C9.72621 15.1147 9.72421 14.2978 9.20432 13.7951L4.54499 9.29028L32.6719 9.29028C33.4054 9.29028 34 8.7126 34 7.99996C34 7.28731 33.4054 6.70964 32.6719 6.70964L4.54506 6.70963L9.20426 2.20486C9.72415 1.70215 9.72614 0.885178 9.2087 0.38008C8.6912 -0.125145 7.85023 -0.126886 7.3304 0.375694L0.390687 7.08538L0.389493 7.0866C-0.130668 7.59099 -0.129009 8.4106 0.389558 8.91331Z" fill="%23C9DCD1"%3E%3C/path%3E%3C/svg%3E');
}

.supplement-item {
  height: 15rem;
  transition: all 0.5s ease-out;

  img {
    transition: all 0.5s ease-out;
  }

  .supplement-item-text {
    transition: all 0.3s ease-in;
    opacity: 1;
  }
}

.next-supplement-slide,
.prev-supplement-slide {
  height: 9.5rem;

  img {
    width: 8rem;
    height: 13.5rem;
  }

  .supplement-item-text {
    opacity: 0;
  }
}

.prev-supplement-slide {
  img {
    transition: all 0.5s ease-in;
  }
}

.supplement-slider-wrapper .swiper-slide-active {
  @media only screen and (min-width: 768px) and (max-width: 870px) {
    width: 327px !important;
  }

  @media only screen and (min-width: 998px) and (max-width: 1024px) {
    width: 452px !important;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    width: 288px !important;
  }
}

.ingredient-popup-image {
  position: absolute;
  animation: confetti-fall 3s forwards;
  top: -0.1rem;
  right: 0rem;

  @media only screen and (min-width: 400px) and (max-width: 768px) {
    right: 2rem;
  }

  @media only screen and (min-width: 870px) and (max-width: 1023px) {
    right: 4rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    top: -0.3rem;
  }

  @media only screen and (min-width: 1024px) {
    top: -1.2rem;
    right: 0.5rem;
  }

  @media only screen and (min-width: 1535px) {
    right: 4.3rem;
  }
}

.invisible {
  opacity: 0;
  transform: translateY(5rem);
}

.animate__slideUp {
  animation: slideUp 0.6s forwards;
  animation-delay: 0.4s;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}