.leckerlie-product-container {
  .product-card {
    position: relative;
    background: var(--kt-light);
    height: 100%;

    .badge {
      position: absolute;
      left: 0;
      top: 2rem;
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      background: var(--kt-secondary);
      color: var(--kt-white);
      padding: 0.3rem 1rem;
    }

    .product-tumb {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem;
      height: 20rem;
      background: var(--kt-primary-light);

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .product-details {
      padding: 3rem;

      .product-title {
        font-weight: $font-weight-base;
        display: block;
        margin-bottom: 1.8rem;
        text-transform: uppercase;
        color: var(--kt-primary);
        text-decoration: none;
        transition: 0.3s;
      }

      .product-description {
        font-size: 1.5rem;
        line-height: 2.2rem;
        margin-bottom: 1.8rem;
        color: var(--kt-light-inverse);
      }

      .leckerlie-description li {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }

    .product-bottom-details {
      overflow: hidden;
      border-top: 1px solid var(--kt-light);
      padding-top: 2rem;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product-price {
        float: left;
        width: 50%;
        font-size: 1.8rem;
        color: var(--kt-primary);
        font-weight: $font-weight-base;
      }

      .product-link {
        text-align: right;

        a {
          display: inline-block;
          margin-left: 0.5rem;
          color: var(--kt-primary);
          transition: 0.3s;
          font-size: 1.7rem;

          :hover {
            color: var(--kt-primary);
          }
        }
      }
    }
  }
}

.leckere-info {
  padding-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.75rem;
  text-align: left;
  font-weight: $font-weight-base;

  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
    line-height: 2.25rem;
  }

  a {
    color: var(--kt-primary);
    cursor: pointer;
    text-decoration: underline;
    font-weight: $font-weight-bold;
  }
}
