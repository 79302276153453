//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: 0;

    .content-container {
      // filter: var(--kt-filter-drop-shadow);
      box-shadow: var(--hb-box-shadow);
      border-radius: 3rem 3rem 0 0;
      background-color: var(--kt-card-bg);
      width: 100%;
      max-width: calc(100% - 300px) !important;
    }

    .main-content {
      padding: 2rem;
    }
  }
}

$number-of-options: 3;

#form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
}

#debt-amount-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 5rem;
  user-select: none;

  &::before {
    content: ' ';
    position: absolute;
    height: 3px;
    width: 100%;
    width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #c9dcd1;
  }

  input,
  label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
  }

  label {
    display: inline-block;
    position: relative;
    width: 20%;
    height: 100%;
    user-select: none;

    &::before {
      content: attr(data-debt-amount);
      position: absolute;
      left: 50%;
      transform: translate(-50%, 45px);
      font-size: 14px;
      letter-spacing: 0.4px;
      font-weight: normal;
      white-space: nowrap;
      opacity: 0.85;
      transition: all 0.15s ease-in-out;
    }

    &::after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border: 4px solid #00756c;
      background: #fff;
      border-radius: 50%;
      pointer-events: none;
      user-select: none;
      z-index: 1;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }

    &:hover::after {
      transform: translate(-50%, -50%) scale(1.25);
    }
  }

  input {
    display: none;

    &:checked {
      + label::before {
        font-weight: $font-weight-extra-bold;
        opacity: 1;
        color: #00756c;
      }

      + label::after {
        border-width: 4px;
        background-color: #00756c;
        transform: translate(-50%, -50%) scale(1.5);
      }
    }
  }
}

#debt-amount-slider {
  input {
    + label::before {
      transform: translate(-50%, 45px) scale(0.9);
      transition: all 0.15s linear;
    }

    &:checked + label::before {
      transform: translate(-50%, 45px) scale(1.1);
      transition: all 0.15s linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }

  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}

#active-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
}

#active-debt-amount-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 5rem;
  user-select: none;

  &::before {
    content: ' ';
    position: absolute;
    height: 3px;
    width: 100%;
    width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #c9dcd1;
  }

  input,
  label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
  }

  label {
    display: inline-block;
    position: relative;
    width: 20%;
    height: 100%;
    user-select: none;

    &::before {
      content: attr(data-debt-amount);
      position: absolute;
      left: 50%;
      transform: translate(-50%, 45px);
      font-size: 14px;
      letter-spacing: 0.4px;
      font-weight: normal;
      white-space: nowrap;
      opacity: 0.85;
      transition: all 0.15s ease-in-out;
    }

    &::after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border: 4px solid #00756c;
      background: #fff;
      border-radius: 50%;
      pointer-events: none;
      user-select: none;
      z-index: 1;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }

    &:hover::after {
      transform: translate(-50%, -50%) scale(1.25);
    }
  }

  input {
    display: none;

    &:checked {
      + label::before {
        font-weight: $font-weight-extra-bold;
        opacity: 1;
        color: #00756c;
      }

      + label::after {
        border-width: 4px;
        background-color: #00756c;
        transform: translate(-50%, -50%) scale(1.5);
      }
    }
  }
}

#active-debt-amount-slider {
  input {
    + label::before {
      transform: translate(-50%, 45px) scale(0.9);
      transition: all 0.15s linear;
    }

    &:checked + label::before {
      transform: translate(-50%, 45px) scale(1.1);
      transition: all 0.15s linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }

  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}
