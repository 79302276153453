.subscription-reactivate {
  border-radius: 2rem;
  border: 0.2rem solid $gray-200;
  display: flex;
  max-width: 40rem;

  &-package {
    background-color: $gray-200;
    border-radius: 1.8rem 0 0 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 11rem;

    img {
      width: 8.3rem;
      margin: 0 1.3rem;
    }
  }

  &-package-resume {
    background-color: #faf0cd80;
  }

  &-content {
    padding: 2rem;
    display: flex;
    flex-direction: column;

    h5 {
      font-weight: $font-weight-bold;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 1.6rem;
      text-transform: uppercase;
    }

    span {
      font-size: 1.4rem;
      $font-weight: $font-weight-base;
      line-height: 1.75rem;
      margin-bottom: 2.5rem;
    }

    p {
      color: #a7a2a2;
      font-weight: $font-weight-base;
      font-size: 1.4rem;
      line-height: 1.75rem;
      margin-bottom: 2.5rem;
    }

    button {
      padding: 1.2rem 5.1rem;
      background-color: var(--kt-secondary);
      color: var(--kt-white);
      font-weight: $font-weight-bold;
      font-size: 1.6rem;
      line-height: 2rem;
      border-radius: 4.5rem;
      max-width: 22rem;
      white-space: nowrap;
      min-height: 4.8rem;

      @include media-breakpoint-up(md) {
        padding: 1.2rem 5.6rem;
      }
    }
  }
}

.subscription-resume {
  border: 0.2rem solid #faf0cd;
  max-width: 50.7rem;
}

.subscription-reactivation {
  &-content {
    @include media-breakpoint-up(md) {
      width: 55%;
      border-radius: 3rem;
      box-shadow: 0px 0px 5px 0px #00756c40;
      padding: 3rem 0;
    }

    @include media-breakpoint-up(xxl) {
      width: 50%;
    }
  }
  .headline-wrapper {
    margin-bottom: 1.6rem;
    padding: 0 2rem;

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-bottom: 3rem;
    }
  }

  .headline {
    font-weight: $font-weight-extra-bold;
    font-size: 1.8rem;
    line-height: 2.25rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      gap: 1rem;
    }

    &-line {
      margin-left: 0;
    }
  }

  &-status-cancelled,
  &-status-paused {
    background-color: $gray-200;
    border-radius: 2rem;
    padding: 0.3rem 0.8rem;
    color: var(--kt-black);
    text-transform: capitalize;
    font-style: italic;
    font-size: 1.4rem;
    line-height: 1.75rem;
    font-weight: $font-weight-bold !important;
    position: absolute;
    right: -2rem;

    @include media-breakpoint-up(md) {
      position: static;
    }
  }

  &-status-paused {
    background-color: #faf0cd;
  }

  &-text {
    font-size: 1.4rem;
    font-weight: $font-weight-base;
    line-height: 1.8rem;
    margin-bottom: 2.4rem;
    padding: 0 2rem;

    @include media-breakpoint-up(md) {
      padding: 0 3rem;
    }

    p {
      margin-bottom: 1.6rem;
    }

    &-description {
      margin-bottom: 1.6rem;
    }
  }

  &-order {
    border-radius: 3rem;
    box-shadow: 0px 0px 5px 0px #00756c40;
    padding: 2rem;
    margin: 0 1rem;

    @include media-breakpoint-up(md) {
      margin: 0;
      box-shadow: none;
      padding: 0 3rem;
    }

    &-headline {
      font-family: $font-family-caveat !important;
      font-weight: $font-weight-bold;
      font-size: 2.4rem;
      line-height: 2.6rem;
      margin-bottom: 2rem;
    }
  }

  &-order-items {
    margin-bottom: 2.4rem;
    .update-subscription-item {
      flex-direction: column;
      gap: 0;
      margin-bottom: 0;

      img {
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &-recipes {
    .dashed-border {
      background-image: linear-gradient(
          90deg,
          transparent,
          transparent 50%,
          var(--kt-white) 50%,
          var(--kt-white) 100%
        ),
        linear-gradient(90deg, #c9dcd1, #c9dcd1, #c9dcd1, #c9dcd1, #c9dcd1);
      margin-top: 0;
      margin-bottom: 2.4rem;
    }
  }

  &-dropdown {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: $font-weight-bold;
    color: var(--kt-primary);
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include media-breakpoint-up(md) {
      gap: 0.8rem;
      font-size: 1.6rem;
    }

    svg {
      width: 1rem;
      margin-top: 0.1rem;
    }

    &-title {
      font-weight: $font-weight-bold;
      line-height: 1.75rem;
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }

    &-items {
      margin-bottom: 2.4rem;
    }
  }

  &-discount {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;
    }

    span {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.5rem;
      background-color: #ffd380;
      border-radius: 2rem;
    }
  }

  &-price {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: var(--kt-primary);
    margin-bottom: 2.5rem;

    &-info {
      font-weight: $font-weight-extra-bold;
      font-size: 2.2rem;
      line-height: 2.75rem;

      div {
        font-weight: $font-weight-base;
        font-size: 1.4rem;
        line-height: 1.75rem;
      }
    }

    &-summary {
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 0.5rem;
      p {
        color: var(--kt-gray-600);
        font-size: 1.8rem;
        line-height: 2.25rem;
        font-weight: $font-weight-base;
        text-decoration: line-through;
      }

      span {
        font-size: 2.6rem;
        font-weight: $font-weight-extra-bold;
        line-height: 3.25rem;
        color: var(--kt-primary);
      }
    }
  }

  &-button {
    padding: 1.3rem;
    width: 100%;
    color: var(--kt-white);
    background-color: var(--kt-secondary);
    font-weight: $font-weight-bold;
    font-size: 1.8rem;
    line-height: 2.25rem;
    border-radius: 3rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem;
      max-width: 36rem;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
    font-size: 1.4rem;
    line-height: 1.75rem;
    font-weight: $font-weight-bold;
    color: var(--kt-primary);
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      font-size: 1.6rem;
      justify-content: center;
      line-height: 2rem;
    }

    svg {
      width: 1rem;

      @include media-breakpoint-up(md) {
        width: 1.3rem;
      }
    }
  }

  &-date {
    background-color: #ecf1ee;
    border-radius: 3rem;

    img {
      width: 29.8rem;
      margin: 0 auto 4.2rem auto;
    }

    @include media-breakpoint-up(md) {
      width: 45%;
    }

    @include media-breakpoint-up(xxl) {
      width: 50%;
    }
  }

  &-calendar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      margin-bottom: 4.1rem;
    }

    p {
      position: absolute;
      background-color: white;
      padding: 0 0.5rem;
      top: -1rem;
      left: 0.5rem;
      font-weight: $font-weight-base;
      font-size: 1.4rem;
      line-height: 1.75rem;

      @include media-breakpoint-up(md) {
        background-color: #ecf1ee;
      }

      @include media-breakpoint-between(lg, xl) {
        font-size: 1.2rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.8rem;
        line-height: 2rem;
        left: 1.5rem;
        background-color: #ecf1ee;
      }
    }
    &-date,
    &-delivery {
      border-radius: 1rem;
      padding: 1.9rem 1.5rem 1.3rem 1.5rem;
      font-weight: $font-weight-extra-bold;
      font-size: 1.4rem;
      line-height: 1.75rem;
      position: relative;

      @include media-breakpoint-between(lg, xl) {
        font-size: 1rem;
        padding: 1.9rem 1.2rem 1rem 1.2rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.8rem;
        line-height: 2.25rem;
        padding: 2.6rem 3.06rem 2.6rem 2.9rem;
      }
    }

    &-date {
      border: 2px solid #cadcd1;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        width: 1rem;

        @include media-breakpoint-up(md) {
          width: 1.3rem;
        }
      }
    }

    &-delivery {
      border: 2px solid #d2d2d2;
    }
  }

  &-fade-left,
  &-fade-right {
    position: absolute;
    font-size: 5rem;
    line-height: 6.25rem;
    font-weight: $font-weight-extra-bold;
    color: #ffffff4d;
  }

  &-fade-left {
    transform: rotate(270deg);
    left: -13rem;
    bottom: 17.8rem;
  }

  &-fade-right {
    transform: rotate(-90deg);
    right: -11rem;
    bottom: 16rem;
  }
}
