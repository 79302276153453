.subscription-dropdown {
  padding: 0.7rem 3rem;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.8rem;
  min-width: 12rem;

  @include media-breakpoint-up(md) {
    padding: 1rem 3.1rem;
    min-width: 13rem;
  }

  &-active {
    border: 1px solid var(--kt-primary);
    box-shadow: 0 0 5px rgba(0, 117, 108, 0.5);
    color: var(--kt-primary);
  }

  &-paused {
    border: 1px solid #f5e2be;
    box-shadow: 0 0 5px rgba(245, 226, 190, 1);
    color: #edbf69;
  }

  &-cancelled {
    border: 1px solid #c1c1c1;
    box-shadow: 0 0 5px rgba(193, 193, 193, 1);
    color: #a5a3a3;
  }

  svg {
    width: 1.2rem;
    height: 0.7rem;

    @include media-breakpoint-up(md) {
      width: 1.5rem;
      height: 0.9rem;
    }
  }

  &-pet-name {
    max-width: 7.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    line-height: 22px;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 1.1rem 2.5rem;
  }

  &-list {
    position: absolute;
    left: 0;
    margin-top: 0.5rem;
    min-width: 12rem;
    text-align: center;
    background-color: var(--kt-white);
    border-radius: 0.8rem;
    box-shadow: var(--hb-box-shadow);
    z-index: 10;

    @include media-breakpoint-up(lg) {
      min-width: 13rem;
    }

    p {
      padding: 0.8rem 1.6rem;
      font-size: 1.3rem;
      line-height: 2.2rem;
      text-align: left;
      background-color: var(--kt-light);
      color: var(--kt-primary);
      text-transform: uppercase;
      font-weight: $font-weight-base;
      border-radius: 0.8rem 0.8rem 0 0;

      @include media-breakpoint-up(md) {
        font-size: 1.4rem;
      }
    }
  }

  &-item {
    text-align: left;
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
    line-height: 2.2rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }
}

.subscription-switcher {
  border-radius: 0.8rem;
  min-height: 16rem;

  &-status {
    background-color: var(--kt-white);
    border-radius: 2rem;
    padding: 0.3rem 0.8rem;
    font-weight: bold;
    font-size: 1.4rem;
    font-style: italic;
    line-height: 2.2rem;
    color: #c1c1c1;
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    margin: 0 auto 0.5rem auto;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--kt-white);
    background-color: var(--kt-white);
    font-weight: $font-weight-bold;
    padding: 0.3rem;
    min-width: 10rem;
    border-radius: 1rem;
    height: 14rem;

    @include media-breakpoint-up(md) {
      min-width: 11rem;
      padding: 0.5rem;
      height: 16rem;
    }
  }

  &-item-wrapper {
    font-size: 1.8rem;
    color: var(--kt-white);
    font-weight: $font-weight-extra-bold;
    height: 100%;
    width: 10rem;
  }

  &-item {
    position: relative;
    background-color: var(--kt-primary);
    height: 7.3rem;
    width: 100%;
    border-radius: 0.5rem;
  }

  &-name {
    line-height: 2.2rem;
    left: 1rem;
    right: 1rem;
    text-align: left;
    position: absolute;
    max-width: 9.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }

  &-gender {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
  }
}

.subscription-pet-year {
  padding: 1.5rem 0 1rem 1rem;

  font-size: 1.4rem;
  font-weight: $font-weight-base;

  @include media-breakpoint-up(md) {
    padding: 1.2rem 0 3rem 1rem;
  }

  &-item {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.7rem;
    margin-bottom: 0.5rem;
    height: 1.7rem;
    line-height: 2.2rem;
  }

  svg {
    width: 1.5rem;
  }

  .subscription-pet-breed {
    max-width: 9.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.subscription-add-new {
  border-top: 1px dashed #cadcd1 !important;
  color: var(--kt-primary);

  svg {
    width: 0.6rem;
    height: 0.6rem;

    @include media-breakpoint-up(md) {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}
