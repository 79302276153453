.reward-banner {
  padding: 2rem 1rem 1.5rem 2rem;
  background: rgb(0, 117, 108);
  background: linear-gradient(90deg, rgba(0, 117, 108, 1) 39%, rgba(42, 152, 144, 1) 82%);
  position: relative;

  @include media-breakpoint-up(lg) {
    width: calc(100% + 4rem);

    &::after {
      content: '';
      border: 1rem solid;
      border-color: #00675f transparent transparent #00675f;
      position: absolute;
      right: 0;
      bottom: -1.9rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 4.5rem 3rem !important;
  }

  @include media-breakpoint-up(md) {
    min-height: 100%;
  }

  @include media-breakpoint-up(xl) {
    padding: 3.5rem 3rem;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #13131373;
    z-index: 123;
  }

  &-title {
    font-size: 1.8rem;
    font-weight: $font-weight-extra-bold;
    line-height: 22.5px;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;
      line-height: 27.5px;
      margin-bottom: 3rem;
      min-width: 24rem;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-bottom: 1.5rem;
    }
  }

  &-ribbon {
    position: relative;
    margin-top: -3.4rem;
    z-index: 2;

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      right: 2.4rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 1279px) {
      top: -1rem;
    }

    svg {
      width: 6rem;
      height: 8.6rem;

      @include media-breakpoint-up(md) {
        width: 8.8rem;
        height: 9rem;
        margin-top: -1rem;
      }

      @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        width: 8.8rem;
        height: 9rem;
      }

      @include media-breakpoint-up(xl) {
        width: 9rem;
        height: 9rem;
      }
    }

    p {
      position: absolute;
      top: 1.7rem;
      left: -0.5rem;
      right: 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: $font-weight-bold;
      font-size: 2rem;
      line-height: 2.5rem;

      @include media-breakpoint-up(md) {
        font-size: 2.4rem;
        line-height: 3rem;
        top: 0.5rem;
      }

      span {
        font-size: 1.4rem;
        line-height: 1.75rem;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }
  }

  &-animated {
    @media only screen and (min-width: 768px) and (max-width: 900px) {
      display: none;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      display: none;
    }
  }

  &-message {
    font-size: 1.4rem;
    line-height: 17.5px;
    font-weight: $font-weight-base;
    max-width: 27rem;

    @media only screen and (min-width: 360px) and (max-width: 385px) {
      font-size: 1.3rem;
    }

    @media only screen and (min-width: 385px) and (max-width: 767px) {
      max-width: 28rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 22.5px;
      max-width: 25rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 29rem;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 36rem;
    }

    span {
      font-weight: $font-weight-extra-bold;
    }
  }

  &-message-almost {
    font-size: 1.4rem;
    line-height: 17.5px;
    font-weight: $font-weight-base;
    max-width: 29rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 22.5px;
    }

    @media only screen and (min-width: 1280px) and (max-width: 1535px) {
      max-width: 25rem;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      font-size: 1.6rem;
    }

    span {
      font-weight: $font-weight-extra-bold;
    }
  }

  &-fade {
    font-size: 3rem;
    line-height: 5rem;
    font-weight: $font-weight-bold;
    opacity: 0.04;
    position: absolute;
    top: 3.5rem;
    text-transform: uppercase;

    @media only screen and (min-width: 768px) and (max-width: 1279px) {
      font-size: 3rem;
      top: 6rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 4rem;
      top: 5.5rem;
    }
  }

  &-confetti {
    position: absolute;
    top: -1rem !important;

    @include media-breakpoint-up(md) {
      top: -7rem !important;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      display: none;
    }
  }

  &-discount {
    font-size: 3.5rem;
    font-weight: $font-weight-extra-bold;
    line-height: 2.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.reward-sticky {
  background: var(--kt-secondary);
  border-radius: 1.5rem 1.5rem 0 0rem;
  color: var(--kt-white);
  font-weight: $font-weight-bold;
  font-size: 1.6rem;
  line-height: 2rem;
  display: inline-block;
  height: 3.4rem;
  width: 10.4rem;
  position: fixed;
  z-index: 999;
  right: 0;
  transform: rotate(-90deg);
  transform-origin: bottom right;
}

.reward-sticky-close {
  background: var(--kt-secondary);
  border-radius: 1.5rem 1.5rem 0 0;
  color: var(--kt-white);
  font-weight: $font-weight-bold;
  font-size: 1.6rem;
  line-height: 2rem;
  height: 3.4rem;
  width: 10.4rem;
  transform: rotate(-90deg) translateX(-50%);
  position: absolute;
  left: -6.9rem;
  top: 5%;
}

.reward-banner-mobile {
  background-color: var(--kt-white) !important;
  background: var(--kt-white) !important;
  color: var(--kt-primary) !important;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.reward-banner-mobile.slide-in {
  animation-name: slideInRight;
}

.reward-banner-mobile.slide-out {
  animation-name: slideOutRight;
}

.reward-banner-sticky {
  position: fixed;
  z-index: 999;
  width: 90%;
  right: 0;
  transform: rotate(-90deg);
  transform-origin: bottom right;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.reward-banner-md-scr {
  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 50%;
  }
}

.no-reward-banner {
  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 4.5rem 3rem !important;
  }

  &-image {
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
      display: none;
    }
  }

  &-fade {
    top: 2.6rem;

    @media only screen and (min-width: 375px) and (max-width: 767px) {
      top: 4.2rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      top: 6.2rem !important;
    }

    @include media-breakpoint-up(lg) {
      top: 5.4rem !important;
    }
  }
}
