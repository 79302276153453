.address-update-modal {
  padding: 4.5rem 2rem;
  @include media-breakpoint-up(md) {
    padding: 4rem 3rem;
  }

  &-text {
    font-weight: $font-weight-base;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.8rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2.25rem;
    }

    p {
      margin-bottom: 1.8rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }
  }

  button {
    text-align: center;
    background-color: var(--kt-primary);
    color: var(--kt-white);
    border-radius: 4.5rem;
    padding: 1.25rem 4.6rem;
    font-weight: $font-weight-base;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    min-width: 26rem;
  }
}

.headline-popup-info {
  position: absolute;
  top: 4rem;
  z-index: 10;
  background-color: #f0f8f3;
  box-shadow: 0rem 0.2rem 0.5rem 0.2rem #00000029;
  border-radius: 1rem;
  padding: 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--kt-primary);
  font-weight: $font-weight-medium;
  max-width: 45rem;

  span {
    font-weight: $font-weight-extra-bold;
  }

  @media only screen and (max-width: 431px) {
    width: 100%;
  }

  @media only screen and (min-width: 431px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  @include media-breakpoint-up(lg) {
    left: -15.5rem;
  }
}
