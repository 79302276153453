.cancellation-offer {
  &-title {
    font-size: 1.8rem;
    line-height: 2.25rem;
    font-weight: 800;
    color: var(--kt-primary);
    text-transform: uppercase;
    background-size: contain;
    background-repeat: round;
    border-radius: 3rem 3rem 0 0;
    display: flex;
    align-items: center;
    gap: 3rem;
    max-height: 11rem;

    @include media-breakpoint-up(md) {
      position: relative;
      font-size: 2.2rem;
      line-height: 2.75rem;
      color: var(--kt-black);
      border-radius: 3rem 0rem 0rem 3rem;
      max-height: unset;
    }

    .discount-title {
      color: var(--kt-white);
      font-size: 6.4rem;
      line-height: 8rem;
      font-weight: 800;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 1.5rem;
        font-size: 9.6rem;
        line-height: 12rem;
      }
    }
  }

  &-title-discount {
    color: var(--kt-black);
    background-repeat: no-repeat;
    background-size: cover;
    height: 11rem;

    @include media-breakpoint-up(md) {
      width: 23rem;
      height: auto;
    }
  }

  &-content {
    padding: 2rem 2rem 3rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    &-title {
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 800;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        margin-bottom: 2.4rem;
      }
    }

    &-title-received {
      font-family: $font-family-caveat !important;
      font-size: 2.4rem !important;
      font-weight: $font-weight-bold !important;
      line-height: 3rem !important;
      text-transform: none !important;

      @include media-breakpoint-up(md) {
        font-size: 3.2rem !important;
        line-height: 3rem !important;
      }
    }
    &-description {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;
      margin-bottom: 1.6rem;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 2.25rem;
      }
    }

    &-product-name {
      font-size: 1.8rem;
      line-height: 2.25rem;
      font-weight: 800;
      color: var(--kt-white);
      background-repeat: no-repeat;
      background-size: cover;
      text-transform: uppercase;
      max-width: fit-content;
      padding: 0.5rem;
      margin-bottom: 0.75rem;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
        line-height: 2.75rem;
        padding: 0.5rem 1rem;
      }
    }

    &-pricing {
      margin-bottom: 2.5rem;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;

      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        line-height: 2.25rem;
      }

      .product-price-extra {
        font-size: 1.8rem;
        line-height: 2.25rem;
        font-weight: 800;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: fit-content;
        color: var(--kt-primary);
        padding: 0.5rem;

        @include media-breakpoint-up(md) {
          font-size: 2.2rem;
          line-height: 2.75rem;
          padding: 0.5rem 1rem;
        }
      }
    }

    &-extra {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: $font-weight-base;
      margin-bottom: 2.5rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        line-height: 2.25rem;
        max-width: 43rem;
      }
    }
  }

  &-image {
    width: 7rem;
    margin-left: 2rem;
    margin-top: 1.5rem;
    padding: 0.4rem 0;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      width: 21rem;
      margin-left: 0;
      padding: 0 1rem;
    }
  }

  .cancellation-offer-accepted-btn {
    background-color: var(--kt-white) !important;
    color: var(--kt-secondary);
    border: 1px solid var(--kt-secondary);
    font-size: 1.6rem;
    line-height: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .cancellation-offer-accepted-content-pricing {
    margin-bottom: 0.75rem !important;
  }

  .cancellation-offer-accepted-product-name {
    margin-bottom: 2.5rem !important;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem !important;
    }
  }
  .cancellation-offer-reward-received {
    display: none;
  }

  .product-name {
    font-size: 4rem;
  }
}

.cancellation-offer .cancellation-offer-reward-received,
.cancellation-offer .cancellation-offer-reward-received .cancellation-offer-title {
  display: none;
}

.cancellation-offer .cancellation-offer-offer {
  display: block;
}

.cancellation-offer-received .cancellation-offer-reward-received {
  display: block;
}

.cancellation-offer-received .cancellation-offer-offer {
  display: none;
}

body canvas {
  z-index: 9999 !important;
}
