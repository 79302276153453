.order {
  &-tracking {
    $tracking: &;

    &-event {
      &:not(:last-child) {
        #{$tracking}-icon {
          ::before {
            content: '';
            background-color: var(--kt-primary-light);
            width: 2px;
            height: calc(100% + .2rem);
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}